<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="요청 정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline>
                <c-btn
                  label="삭제"
                  :editable="editable"
                  icon="delete_forever"
                />
                <c-btn
                  label="팀장승인"
                  icon="check"
                  color="purple" />
                <c-btn
                  label="저장"
                  icon="save"
                />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-text
                  required
                  :editable="editable"
                  :disabled="disabled"
                  label="작업내용"
                  name="col5"
                  v-model="data.col5"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-field
                  required
                  :editable="editable"
                  :disabled="disabled"
                  :data="data"
                  deptValue="col3"
                  type="dept_user"
                  label="요청부서/담당자"
                  name="col1"
                  v-model="data.col1"
                >
                </c-field>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-field
                  required
                  :editable="editable"
                  :disabled="disabled"
                  :data="data"
                  deptValue="col4"
                  type="dept_user"
                  label="작업부서/담당자"
                  name="col2"
                  v-model="data.col2"
                >
                </c-field>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-plant
                  required
                  :editable="editable"
                  :disabled="disabled"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-select
                  required
                  type="search"
                  itemText="codeName"
                  itemValue="code"
                  :comboItems="comboItems"
                  label="작업분류"
                  v-model="data.col6"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-datepicker
                  required
                  :range="true"
                  label="작업계획기간"
                  v-model="data.period"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                <c-text
                  :afterIcon="
                    editable && !disabled
                      ? [
                          {
                            name: 'search',
                            click: true,
                            callbackName: 'searchWorkPermit',
                            color: 'teal',
                          },
                          {
                            name: 'close',
                            click: true,
                            callbackName: 'removeWorkPermit',
                            color: 'red',
                          },
                        ]
                      : null
                  "
                  :editable="editable"
                  :disabled="true"
                  label="작업허가 발행"
                  name="permitNo"
                  v-model="data.permitNo"
                  @searchWorkPermit="searchWorkPermit"
                  @removeWorkPermit="removeWorkPermit"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
                <c-textarea
                  :editable="editable"
                  :disabled="disabled"
                  label="비고"
                  :rows="1"
                  name="significant"
                  v-model="data.significant"
                >
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-select
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  :comboItems="comboItems2"
                  label="진행상황"
                  v-model="data.col8"
                ></c-select>
              </div>
            </template>
          </c-card>
        </div>
      </div>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
export default {
  name: "tbm-info",
  props: {
  },
  data() {
    return {
      data: {
        plantCd: null,
        col1: '',
        col2: '',
        col3: '',
        col4: '',
        col5: '집진기 백필터 교체',
        permitNo: 'WPO-2023-00028/A-5구역 굴착및 중장비 작업',
        col6: '1',
        period: ['2023-10-25', '2023-10-27'],
        col8: '2',
      },
      comboItems2: [
        {
          code: '2',
          codeName: '계획수립',
        },
        {
          code: '3',
          codeName: '개선중',
        },
        {
          code: '4',
          codeName: '조치완료',
        },
      ],
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        // isFull: true,
        top: "",
        param: {},
        closeCallback: null,
      },
      editable: false,
      disabled: false,
      comboItems: [
        {
          code: '1',
          codeName: '하역기',
        },
        {
          code: '2',
          codeName: '선적기',
        },
        {
          code: '4',
          codeName: 'BC tail',
        },
        {
          code: '5',
          codeName: 'BC head',
        },
        {
          code: '6',
          codeName: 'BC middle',
        },
        {
          code: '7',
          codeName: 'TT',
        },
        {
          code: '8',
          codeName: '창고 1~5구간',
        },
        {
          code: '9',
          codeName: '환경설비',
        },
        {
          code: '10',
          codeName: '안전설비',
        },
      ]
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  computed: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;

      this.getDetail();
    },
    getDetail() {
      this.data.col1 = this.$store.getters.user.userId;
      this.data.col3 = this.$store.getters.user.deptCd;

      this.data.col2 = 'U000000006';
      this.data.col4 = 'D10005'
    },
    
    // 작업허가서 선택
    searchWorkPermit() {
      this.popupOptions.title = "작업허가서 선택"; // 작업허가서 선택
      this.popupOptions.param = {
        type: "single",
        plantCd: this.data.plantCd, 
        popupMode: 'Y',
      };
      this.popupOptions.target = () =>
        import(`${"@/pages/sop/swp/safeWorkPermitPop.vue"}`);
      this.popupOptions.width = "70%";
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeWorkPermitPopup;
    },
    closeWorkPermitPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    removeWorkPermit() {
      
    }
  },
};
</script>